import type { Metadata } from "next";

export const metadata: Metadata & { email: string; titleStudios: string; } = {
  title: "Find Certified Barre Instructors | Barre.Directory",
  titleStudios: 'IBBFA Approved Studios | Barre.Directory',
  description:
    "Explore top certified barre instructors in your area with our extensive directory. Ideal for studios hiring professional instructors and individuals seeking local barre classes. Connect with expert barre fitness professionals easily.",
  keywords:
    "certified barre instructors, barre fitness classes, local barre instructors, hire barre instructor, barre instructor directory, find barre classes, barre fitness professionals",
  authors: {
    name: 'Barre Certification'
  },
  email: 'info@barrecertification.com',
  themeColor: '#ffffff',
  openGraph: {
    title: 'Find Certified Barre Instructors | Barre.Directory',
    description: 'Explore top certified barre instructors in your area with our extensive directory. Ideal for studios hiring professional instructors and individuals seeking local barre classes. Connect with expert barre fitness professionals easily.',
    url: 'https://barre.directory/'
  },
  publisher: 'Discover True You, Inc.'
};
