import Head from "next/head";
import type { NextPage, NextPageContext } from "next";
import { Main } from "@instructors/components";
import { InstructorEntity, InstructorMapMark } from "@instructors/types";
import { fetchInstructors, fetchMapMarks } from "@instructors/network";
import { CommonConfig } from "@common/configs/CommonConfig";
import { metadata } from "@common/constants";

type HomePageProps = {
  mapMarks: InstructorMapMark[];
  instructors: InstructorEntity[];
};

const Home: NextPage<HomePageProps> = ({ mapMarks, instructors }) => {
  return (
    <>
      <Head>
        <title>{metadata.title as string}</title>
        <meta name="description" content={metadata.description as string} />
        <meta
          property="og:title"
          content={metadata.openGraph?.title as string}
        />
        <meta
          property="og:description"
          content={metadata.openGraph?.description as string}
        />
        <meta property="og:url" content={metadata.openGraph?.url as string} />
      </Head>
      <Main mapMarks={mapMarks} instructors={instructors} />
    </>
  );
};

export const getServerSideProps = async (context: NextPageContext) => {
  try {
    const mapMarks = await fetchMapMarks();

    const instructors = await fetchInstructors({
      enabled: "1",
      order_value: CommonConfig.tldRegion,
      order: "site_code",
      limit: "30",
    });

    return { props: { mapMarks, instructors } };
  } catch (e) {
    console.error(e);

    return { props: { mapMarks: [] } };
  }
};

export default Home;
